import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, Layout, Seo, TextContent } from "../../components"
import * as textStyles from "../../components/text-content.module.scss"

export default function WieIsFreija({ data }) {
  var heroImage = getImage(data.file)
  var title = "Audiometrie"

  return (
    <Layout site="audio" page={`/diensten`}>
      <Seo title={title} shop="audio" />
      <Hero image={heroImage} site="audio">
        {title}
      </Hero>
      <TextContent title={title} span="Meten is weten.">
        <h4>Meetinstrumenten</h4>
        <p className={textStyles.paragraph}>
          Bij Wim's hoorcenter worden uitgebreide tonale- & vocale gehoortesten,
          uitgevoerd in een geluidsdichte cabine. Deze testen worden, zonder
          enige externe storing, uitgevoerd en voldoen daarmee aan de hoogste
          normen. Daarnaast maakt Wim gebruik van de Freefit-technologie in
          vrijveld. Fijne Probe-microfoontjes meten wat je hoorapparaten
          daadwerkelijk versterken. Hierbij wordt het effect van hoorapparaat in
          kaart gebracht.
        </p>
        <h4>Meetomgeving</h4>
        <p className={textStyles.paragraph}>
          Naast de metingen in de geluidsdichte cabine, kunnen alledaagse
          situaties nagebootst worden. Tijdens het aanpassen van je
          hoorapparaatjes, kan de fijnregeling onmiddellijk afgetoetst worden in
          een meer realistische omgeving. Zó waan je je bijvoorbeeld op
          restaurant of in een concertzaal. Het volgende moment sta je
          ogenschijnlijk midden in een productiehal of hoor je getjirp van
          vogeltjes in het bos. Wim polst op regelmatige basis naar je ervaring
          met de nieuwe versterking. Hierdoor tilt Wim de fijnregeling van je
          hoorapparaatjes naar een hoger niveau.
        </p>
      </TextContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "audiometrie-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
